import React from 'react';

export default function GroupPurchasing() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-[#00ccfe]/5 rounded-2xl shadow-xl p-8 md:p-12">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Group Purchasing Program Available</h2>
              <p className="text-xl text-gray-600 mb-8">
                Join forces with other healthcare providers and leverage the power of group purchasing. By pooling orders, you can benefit from exclusive discounts and greater purchasing power, helping to reduce costs even further.
              </p>
              <button 
                onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
                className="bg-[#00ccfe] text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-[#00ccfe]/90 transition-colors"
              >
                Learn More
              </button>
            </div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&w=800&q=80"
                alt="Healthcare professionals collaborating"
                className="rounded-xl shadow-lg"
              />
              <div className="absolute -bottom-6 -right-6 bg-white p-4 rounded-lg shadow-lg">
                <p className="text-2xl font-bold text-[#00ccfe]">Save More</p>
                <p className="text-sm text-gray-600">Group Purchasing Power</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}