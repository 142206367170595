import React from 'react';

export default function About() {
  return (
    <section id="about" className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Our Commitment to Quality Care
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              At BWell Company, we're committed to ensuring every family finds the right care home. Our platform connects you with quality residential assisted living communities that meet your specific needs.
            </p>
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-[#00ccfe] mt-1"></div>
                <p className="ml-4 text-gray-600">Comprehensive network of vetted care homes</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-[#00ccfe] mt-1"></div>
                <p className="ml-4 text-gray-600">Expert local support when you need it</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-[#00ccfe] mt-1"></div>
                <p className="ml-4 text-gray-600">No-cost direct access to facilities</p>
              </div>
            </div>
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1586105251261-72a756497a11?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
              alt="Senior care"
              className="rounded-xl shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}