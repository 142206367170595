import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/HealgenSalesPage/Hero';
import Benefits from '../components/HealgenSalesPage/Benefits';
import IdealFor from '../components/HealgenSalesPage/IdealFor';
import Pricing from '../components/HealgenSalesPage/Pricing';
import GroupPurchasing from '../components/HealgenSalesPage/GroupPurchasing';
import Medicare from '../components/HealgenSalesPage/Medicare';
import ContactForm from '../components/HealgenSalesPage/ContactForm';
import Footer from '../components/Footer';

export default function HealgenSalesPage() {
  return (
    <>
      <Navbar />
      <Hero />
      <Benefits />
      <IdealFor />
      <Pricing />
      <GroupPurchasing />
      <Medicare />
      <ContactForm />
      <Footer />
    </>
  );
}