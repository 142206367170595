import React from 'react';
import { Shield, Heart, CheckCircle } from 'lucide-react';

export default function Benefits() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">Designed for Excellence</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="bg-white p-6 rounded-full w-24 h-24 mx-auto mb-6 shadow-lg">
              <Shield className="h-12 w-12 text-[#00ccfe]" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Safe Handling</h3>
            <p className="text-gray-600">Rounded edges and integrated eyelet for secure attachment</p>
          </div>
          <div className="text-center">
            <div className="bg-white p-6 rounded-full w-24 h-24 mx-auto mb-6 shadow-lg">
              <Heart className="h-12 w-12 text-[#00ccfe]" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Patient Safety</h3>
            <p className="text-gray-600">Rigorous testing and monitoring for infusion reactions</p>
          </div>
          <div className="text-center">
            <div className="bg-white p-6 rounded-full w-24 h-24 mx-auto mb-6 shadow-lg">
              <CheckCircle className="h-12 w-12 text-[#00ccfe]" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Convenience</h3>
            <p className="text-gray-600">Compatible with non-vented administration sets</p>
          </div>
        </div>
      </div>
    </section>
  );
}