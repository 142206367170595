import React from 'react';
import { Search } from 'lucide-react';

export default function Hero() {
  return (
    <div className="pt-16 bg-gradient-to-b from-[#00ccfe]/5 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            Find the Perfect
            <span className="text-[#00ccfe]"> Residential Care</span>
          </h1>
          <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
            Direct access to quality residential assisted living communities across the country, at no cost to families.
          </p>
          <div className="relative max-w-2xl mx-auto mb-16">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-4 border border-gray-300 rounded-full text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#00ccfe] focus:border-transparent"
              placeholder="Enter your location to find care homes near you"
            />
            <button className="absolute right-2 top-2 bottom-2 px-8 bg-[#00ccfe] text-white rounded-full hover:bg-[#00ccfe]/90 transition-colors">
              Search
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-semibold mb-4">Direct Access</h3>
              <p className="text-gray-600">Connect directly with care homes through our platform, with no referral fees.</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-semibold mb-4">Local Support</h3>
              <p className="text-gray-600">Get personalized guidance from experienced local placement professionals.</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-xl font-semibold mb-4">Quality Care</h3>
              <p className="text-gray-600">Find the perfect fit with our comprehensive vetting process.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}