import React from 'react';

export default function Sizes() {
  const sizes = [
    { volume: '50 mL', description: 'Perfect for small volume needs' },
    { volume: '100 mL', description: 'Ideal for short procedures' },
    { volume: '250 mL', description: 'Standard size for many applications' },
    { volume: '500 mL', description: 'Common choice for fluid therapy' },
    { volume: '1000 mL', description: 'Suitable for extended treatments' },
  ];

  return (
    <section className="py-20 bg-[#00ccfe]/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">Available Sizes</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {sizes.map((size) => (
            <div key={size.volume} className="bg-white p-6 rounded-xl shadow-lg text-center">
              <h3 className="text-2xl font-bold text-[#00ccfe] mb-2">{size.volume}</h3>
              <p className="text-gray-600">{size.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}