import React from 'react';
import { Search, UserCheck, Home } from 'lucide-react';

export default function HowItWorks() {
  return (
    <section id="how-it-works" className="py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            How It Works
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Finding the right care home is easy with our streamlined process.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="bg-[#00ccfe]/10 rounded-full p-6 inline-block mb-6">
              <Search className="h-8 w-8 text-[#00ccfe]" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Search</h3>
            <p className="text-gray-600">
              Browse our extensive network of residential care homes in your area.
            </p>
          </div>

          <div className="text-center">
            <div className="bg-[#00ccfe]/10 rounded-full p-6 inline-block mb-6">
              <UserCheck className="h-8 w-8 text-[#00ccfe]" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Connect</h3>
            <p className="text-gray-600">
              Connect directly with homes or get help from local placement experts.
            </p>
          </div>

          <div className="text-center">
            <div className="bg-[#00ccfe]/10 rounded-full p-6 inline-block mb-6">
              <Home className="h-8 w-8 text-[#00ccfe]" />
            </div>
            <h3 className="text-xl font-semibold mb-4">Move In</h3>
            <p className="text-gray-600">
              Find the perfect fit and transition smoothly to your new home.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}