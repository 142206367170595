import React from 'react';
import { Building2, Users, FileCheck } from 'lucide-react';

export default function IdealFor() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">Perfect For Healthcare Providers</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <Building2 className="h-12 w-12 text-[#00ccfe] mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Clinics</h3>
            <p className="text-gray-600">Ideal for small to medium-sized medical clinics</p>
          </div>
          <div className="text-center">
            <Users className="h-12 w-12 text-[#00ccfe] mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Hospitals</h3>
            <p className="text-gray-600">Suitable for emergency departments and urgent care</p>
          </div>
          <div className="text-center">
            <FileCheck className="h-12 w-12 text-[#00ccfe] mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Pharmacies</h3>
            <p className="text-gray-600">Perfect for retail pharmacies offering testing services</p>
          </div>
        </div>
      </div>
    </section>
  );
}