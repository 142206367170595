import React from 'react';

export default function Applications() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-6">Indications & Applications</h2>
            <p className="text-xl text-gray-600 mb-8">
              Fleboflex® is designed for versatile medical applications, providing consistent and reliable results in various healthcare settings.
            </p>
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-[#00ccfe] mt-1"></div>
                <p className="ml-4 text-gray-600">Hydration therapy</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-[#00ccfe] mt-1"></div>
                <p className="ml-4 text-gray-600">Electrolyte replenishment</p>
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-[#00ccfe] mt-1"></div>
                <p className="ml-4 text-gray-600">Hemodialysis priming solution</p>
              </div>
            </div>
          </div>
          <div className="relative">
            <img
              src="https://i.ibb.co/CQFyGJK/Leonardo-Phoenix-With-delicate-precision-a-compassionate-nurse-0.jpg?auto=format&fit=crop&w=1200&q=80"
              alt="Nurse administering IV therapy to patient"
              className="rounded-xl shadow-lg w-full h-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
}