import React from 'react';
import { Check } from 'lucide-react';

export default function Benefits() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">Key Benefits</h2>
        <div className="grid md:grid-cols-2 gap-12">
          <div className="bg-gray-50 p-8 rounded-xl">
            <img
              src="https://images.unsplash.com/photo-1579154204601-01588f351e67?auto=format&fit=crop&w=800&q=80"
              alt="Medical testing"
              className="w-full h-48 object-cover rounded-lg mb-6"
            />
            <h3 className="text-2xl font-semibold mb-4">One Sample, Three Results</h3>
            <p className="text-gray-600 mb-6">
              Save time and resources by testing for COVID-19, Flu A, and Flu B with a single nasal swab.
            </p>
            <ul className="space-y-3">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Minimize patient discomfort</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Streamline testing process</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Reduce supply usage</span>
              </li>
            </ul>
          </div>
          <div className="bg-gray-50 p-8 rounded-xl">
            <img
              src="https://images.unsplash.com/photo-1584036561566-baf8f5f1b144?auto=format&fit=crop&w=800&q=80"
              alt="Healthcare professional"
              className="w-full h-48 object-cover rounded-lg mb-6"
            />
            <h3 className="text-2xl font-semibold mb-4">CLIA Waived for Point-of-Care</h3>
            <p className="text-gray-600 mb-6">
              Simple to administer at healthcare settings with minimal training required.
            </p>
            <ul className="space-y-3">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Easy-to-follow instructions</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Quick staff training</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Reliable results</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}