import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import Image from './Image';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <div className="h-12">
              <Image name="logo" alt="BWell Company Logo" className="h-full w-auto" />
            </div>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <a href="#services" className="text-gray-700 hover:text-[#00ccfe]">Services</a>
            <a href="#how-it-works" className="text-gray-700 hover:text-[#00ccfe]">How It Works</a>
            <a href="#about" className="text-gray-700 hover:text-[#00ccfe]">About</a>
            <button className="bg-[#00ccfe] text-white px-6 py-2 rounded-full hover:bg-[#00ccfe]/90 transition-colors">
              Find Care
            </button>
          </div>

          <div className="md:hidden flex items-center">
            <button onClick={() => setIsOpen(!isOpen)} className="text-gray-700">
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a href="#services" className="block px-3 py-2 text-gray-700 hover:text-[#00ccfe]">Services</a>
            <a href="#how-it-works" className="block px-3 py-2 text-gray-700 hover:text-[#00ccfe]">How It Works</a>
            <a href="#about" className="block px-3 py-2 text-gray-700 hover:text-[#00ccfe]">About</a>
            <button className="w-full text-left px-3 py-2 text-white bg-[#00ccfe] rounded-md">
              Find Care
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}