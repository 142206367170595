import React from 'react';
import { DollarSign } from 'lucide-react';

export default function Medicare() {
  return (
    <section className="py-20 bg-[#00ccfe]/5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-2xl shadow-lg p-8 md:p-12">
          <div className="flex items-start gap-6">
            <div className="flex-shrink-0">
              <div className="bg-[#00ccfe]/10 rounded-full p-4">
                <DollarSign className="h-8 w-8 text-[#00ccfe]" />
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold mb-4">Medicare Reimbursement</h2>
              <p className="text-xl text-gray-600">
                Medicare may reimburse for the use of Healgen® COVID-19/Flu A&B Combo Rapid Test under applicable CPT codes, providing cost-effective testing solutions for eligible patients.
              </p>
              <button 
                onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
                className="mt-6 inline-flex items-center text-[#00ccfe] font-semibold hover:text-[#00ccfe]/80 transition-colors"
              >
                Contact us for reimbursement details →
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}