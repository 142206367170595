import React from 'react';
import { Clock, Check, Award } from 'lucide-react';

export default function Hero() {
  return (
    <section className="pt-20 bg-gradient-to-b from-[#00ccfe]/5 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            3-in-1 COVID-19 & Flu Testing Solution
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
            Get rapid, accurate results for COVID-19, Flu A, and Flu B with one simple nasal swab
          </p>
          <button 
            onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
            className="bg-[#00ccfe] text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-[#00ccfe]/90 transition-colors"
          >
            Request Wholesale Pricing
          </button>
        </div>
        
        <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <Clock className="h-12 w-12 text-[#00ccfe] mb-4" />
            <h3 className="text-xl font-semibold mb-2">15-Minute Results</h3>
            <p className="text-gray-600">Rapid results enable faster patient care decisions</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <Check className="h-12 w-12 text-[#00ccfe] mb-4" />
            <h3 className="text-xl font-semibold mb-2">High Accuracy</h3>
            <p className="text-gray-600">Over 90% positive and 99% negative agreement with PCR</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <Award className="h-12 w-12 text-[#00ccfe] mb-4" />
            <h3 className="text-xl font-semibold mb-2">FDA Authorized</h3>
            <p className="text-gray-600">De Novo marketing authorization from FDA</p>
          </div>
        </div>
      </div>
    </section>
  );
}