import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/FleboflexPage/Hero';
import Features from '../components/FleboflexPage/Features';
import Benefits from '../components/FleboflexPage/Benefits';
import Applications from '../components/FleboflexPage/Applications';
import Sizes from '../components/FleboflexPage/Sizes';
import GroupPurchasing from '../components/FleboflexPage/GroupPurchasing';
import ContactForm from '../components/FleboflexPage/ContactForm';
import Footer from '../components/Footer';

export default function FleboflexPage() {
  return (
    <>
      <Navbar />
      <Hero />
      <Features />
      <Benefits />
      <Applications />
      <Sizes />
      <GroupPurchasing />
      <ContactForm />
      <Footer />
    </>
  );
}