import React from 'react';
import { Check } from 'lucide-react';

export default function Pricing() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">Wholesale Pricing Available</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="border border-gray-200 rounded-xl p-8 hover:shadow-lg transition-shadow">
            <h3 className="text-2xl font-semibold mb-4">Tier 1</h3>
            <p className="text-gray-600 mb-6">MOQ 500 units</p>
            <ul className="space-y-3 mb-8">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Bulk discount pricing</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Free shipping</span>
              </li>
            </ul>
            <button 
              onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
              className="w-full bg-[#00ccfe] text-white px-6 py-3 rounded-full hover:bg-[#00ccfe]/90 transition-colors"
            >
              Get Quote
            </button>
          </div>
          <div className="border-2 border-[#00ccfe] rounded-xl p-8 hover:shadow-lg transition-shadow relative">
            <div className="absolute top-0 right-0 bg-[#00ccfe] text-white px-4 py-1 rounded-bl-lg rounded-tr-xl text-sm">
              Popular
            </div>
            <h3 className="text-2xl font-semibold mb-4">Tier 2</h3>
            <p className="text-gray-600 mb-6">10,000 units</p>
            <ul className="space-y-3 mb-8">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Greater volume discount</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Priority shipping</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Dedicated support</span>
              </li>
            </ul>
            <button 
              onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
              className="w-full bg-[#00ccfe] text-white px-6 py-3 rounded-full hover:bg-[#00ccfe]/90 transition-colors"
            >
              Get Quote
            </button>
          </div>
          <div className="border border-gray-200 rounded-xl p-8 hover:shadow-lg transition-shadow">
            <h3 className="text-2xl font-semibold mb-4">Tier 3</h3>
            <p className="text-gray-600 mb-6">20,000+ units</p>
            <ul className="space-y-3 mb-8">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Best wholesale pricing</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Custom shipping options</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>VIP support</span>
              </li>
            </ul>
            <button 
              onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
              className="w-full bg-[#00ccfe] text-white px-6 py-3 rounded-full hover:bg-[#00ccfe]/90 transition-colors"
            >
              Get Quote
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}