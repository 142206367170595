import React from 'react';
import { Shield, CheckCircle } from 'lucide-react';

export default function Hero() {
  return (
    <section className="pt-20 bg-gradient-to-b from-[#00ccfe]/5 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Safe, Reliable, and Convenient IV Solution
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Discover Fleboflex® 0.9% Sodium Chloride Injection—the perfect solution for fluid and electrolyte replenishment.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button 
                onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
                className="bg-[#00ccfe] text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-[#00ccfe]/90 transition-colors"
              >
                Get a Quote
              </button>
              <button className="border-2 border-[#00ccfe] text-[#00ccfe] px-8 py-4 rounded-full text-lg font-semibold hover:bg-[#00ccfe]/5 transition-colors">
                Learn More
              </button>
            </div>
          </div>
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1584036561566-baf8f5f1b144?auto=format&fit=crop&w=800&q=80"
              alt="Medical professional with IV bag"
              className="rounded-xl shadow-lg"
            />
            <div className="absolute -bottom-6 -right-6 bg-white p-4 rounded-lg shadow-lg">
              <div className="flex items-center gap-2">
                <Shield className="h-6 w-6 text-[#00ccfe]" />
                <span className="text-gray-900 font-semibold">USP Certified</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <CheckCircle className="h-12 w-12 text-[#00ccfe] mb-4" />
            <h3 className="text-xl font-semibold mb-2">PVC & Latex-Free</h3>
            <p className="text-gray-600">Made from safe, compatible polypropylene multilayer film</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <Shield className="h-12 w-12 text-[#00ccfe] mb-4" />
            <h3 className="text-xl font-semibold mb-2">USP Class VI</h3>
            <p className="text-gray-600">Passed rigorous biological safety testing</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <CheckCircle className="h-12 w-12 text-[#00ccfe] mb-4" />
            <h3 className="text-xl font-semibold mb-2">Versatile Use</h3>
            <p className="text-gray-600">Compatible with a wide range of medications</p>
          </div>
        </div>
      </div>
    </section>
  );
}