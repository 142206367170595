import { Home, Users, Check } from 'lucide-react';

export default function Services() {
  return (
    <section id="services" className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Our Services
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            We provide comprehensive support for families and healthcare providers in finding the right residential care.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12">
          <div className="bg-white p-8 rounded-xl shadow-lg">
            <Home className="h-12 w-12 text-[#00ccfe] mb-6" />
            <h3 className="text-2xl font-semibold mb-4">Direct Access to Care Homes</h3>
            <p className="text-gray-600 mb-6">
              Access our network of residential assisted living communities directly, with no fees for direct referrals.
            </p>
            <ul className="space-y-3">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>No-cost access to care homes</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Direct communication with facilities</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Nationwide coverage</span>
              </li>
            </ul>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-lg">
            <Users className="h-12 w-12 text-[#00ccfe] mb-6" />
            <h3 className="text-2xl font-semibold mb-4">Professional Support</h3>
            <p className="text-gray-600 mb-6">
              Connect with local placement agents and care coordination agencies for personalized guidance.
            </p>
            <ul className="space-y-3">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Expert local guidance</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Care coordination support</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Personalized assistance</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}