import React from 'react';
import { Check } from 'lucide-react';

export default function Features() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-16">Why Choose Fleboflex®?</h2>
        <div className="grid md:grid-cols-2 gap-12">
          <div className="bg-gray-50 p-8 rounded-xl">
            <h3 className="text-2xl font-semibold mb-4">Material Excellence</h3>
            <p className="text-gray-600 mb-6">
              Our IV bags are crafted from premium polypropylene multilayer film, ensuring maximum safety and compatibility.
            </p>
            <ul className="space-y-3">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>PVC and latex-free construction</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>No plasticizers or adhesives</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Superior drug compatibility</span>
              </li>
            </ul>
          </div>
          <div className="bg-gray-50 p-8 rounded-xl">
            <h3 className="text-2xl font-semibold mb-4">Safety & Durability</h3>
            <p className="text-gray-600 mb-6">
              Every aspect of Fleboflex® is designed with safety and ease of use in mind.
            </p>
            <ul className="space-y-3">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Fully collapsible design</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>Transparent for easy inspection</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#00ccfe] mr-2" />
                <span>USP Class VI certified</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}