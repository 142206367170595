import React from 'react';

interface ImageProps {
  name: string;
  alt: string;
  className?: string;
}

export default function Image({ name, alt, className = '' }: ImageProps) {
  // Map image names to their URLs
  const imageUrls: Record<string, string> = {
    logo: 'https://i.ibb.co/wWLrmD6/BWell-Logo-1.png'
  };

  return (
    <img
      src={imageUrls[name]}
      alt={alt}
      className={className}
    />
  );
}